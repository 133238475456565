.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.search_icon {
  color: #888ea8;
  font-size: 22px;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}

.select_container {
  :global {
    .ant-select-selector {
      height: 36px !important;
      .ant-select-selection-item {
        line-height: 36px !important;
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 14px;
  }

  .ant-select.ant-select-in-form-item {
    width: 100%;
  }
}

.date_container {
  :global {
    .ant-picker {
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 14px;
      width: 100%;
    }
  }
}
.btn_container {
  margin: 8px;
}

.input {
  :global {
    .ant-input {
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 14px;
      width: 100%;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.label {
  font-weight: 600;
}

.input_number {
  :global {
    .ant-input-number-out-of-range input {
      color: black;
    }
    .ant-input-number-handler-up {
      border-top-right-radius: 6px;
    }
    .ant-input-number-handler-down {
      border-bottom-right-radius: 6px;
    }
  }
}

.short_modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      width: 120%;
      height: 80vh;
      overflow: hidden;
    }
    .ant-modal-title {
      font-weight: 600;
      text-align: center;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

.long_modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding-bottom: 20px;
      height: 90vh;
      align-self: center;
      align-items: center;
      margin-top: -50px;
      overflow: hidden;
    }
    .ant-modal-title {
      font-weight: 600;
      text-align: center;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

.bold {
  font-weight: 600;
}

.tag {
  border-radius: 5px !important;
  font-size: 13px !important;
  line-height: 22px !important;
  cursor: pointer;
}

.phone_container {
  display: flex;
  column-gap: 10px;
}
.phone_container > div:last-child {
  width: 100%;
}
.label_input {
  margin: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  position: relative;
  max-width: 100%;
  min-height: 1px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: block;
}

.tagContainer {
  // display: flex;
  // align-items: center;
  :global {
    .ant-tag {
      padding: 6px 10px;
      width: 100%;
      display: block;
      border-radius: 6px;
      font-size: 14px;
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;