.signin_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin_card {
  border-radius: 15px;
  border: 1px solid #e0e6ed;
  min-width: 50px;
  max-width: 490px;
  width: 100%;
}

.signin_title {
  font-size: 32px;
  color: #3b3f5c;
  text-align: center;
}

.signin_desc {
  font-size: 13px;
  color: #888ea8;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
}

.signin_form_item {
  :global {
    .ant-form-item-label > label {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1.5;
      color: #3b3f5c;
    }

    .ant-input-affix-wrapper > input.ant-input {
      width: 100%;
      color: #3b3f5c;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 9px 14px;
    }

    .ant-input-affix-wrapper > input.ant-input::selection {
      background: transparent !important;
      color: #000;
    }

    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #bfc9d4;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:hover {
      box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
      border-color: @secondary-color;
    }

    .ant-form-item-explain-error {
      font-size: 12px;
      line-height: 2;
      text-align: left;
    }
  }
}

a.signin_forgot_password_link {
  font-weight: 600;
  color: #888ea8;
  display: block;
  letter-spacing: 1px;
  font-size: 12px;
  position: absolute;
  right: 25px;
  z-index: 1;
  cursor: pointer;
}

a.signin_forgot_password_link:hover {
  color: #888ea8;
}

.signin_divider {
  font-size: 13px;
  color: #888ea8;
  margin: 35px 0 38px 0;
}

.signin_social_btn {
  background: transparent;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  border-radius: 4px;
  padding: 12px 10px;
  width: 181px;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  color: @secondary-color;
  cursor: pointer;
  letter-spacing: 1px;

  .signin_social_icon {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
}

.signin_social_btn:hover,
.signin_social_btn:focus {
  border: 1px solid #e0e6ed;
  color: @secondary-color;
}

.signin_footer {
  font-size: 14px;
  color: #3b3f5c;
  font-weight: 700;
  text-align: center;
  margin-top: 50px;

  a.signin_signup_link {
    color: @secondary-color;
    border-bottom: 1px solid;
  }

  a.signin_signup_link:hover {
    color: @secondary-color;
  }
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;