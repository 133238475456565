.signup_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_card {
  border-radius: 15px;
  border: 1px solid #e0e6ed;
  min-width: 50px;
  max-width: 490px;
  width: 100%;
}

.signup_title {
  text-align: center;
  font-size: 32px;
  color: #3b3f5c;
}

a.signup_login_link {
  color: @secondary-color;
  border-bottom: 1px solid;
}

a.signup_login_link:hover {
  color: @secondary-color;
}

.signup_desc {
  text-align: center;
  font-size: 13px;
  color: #888ea8;
  font-weight: 600;
  margin-bottom: 35px;
}

.signup_form_item {
  :global {
    .ant-form-item-label > label {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1.5;
      color: #3b3f5c;
    }

    .ant-input-affix-wrapper > input.ant-input {
      width: 100%;
      color: #3b3f5c;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 9px 14px;
    }

    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #bfc9d4;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:hover {
      box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
      border-color: @secondary-color;
    }

    .ant-form-item-explain-error {
      font-size: 12px;
      line-height: 2;
      text-align: left;
    }

    .ant-checkbox-inner {
      border: 1px solid #bfc9d4;
      border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @secondary-color;
      border-color: @secondary-color;
    }

    .ant-checkbox-checked::after {
      border: unset;
    }
  }
  .signup_tnc {
    font-size: 14px;
    letter-spacing: 1px;
    color: #888ea8;
    font-weight: 400;

    a,
    a:hover {
      color: @secondary-color;
    }
  }
}

a.signup_forgot_password_link {
  font-weight: 600;
  color: #888ea8;
  display: block;
  letter-spacing: 1px;
  font-size: 12px;
  position: absolute;
  right: 25px;
  z-index: 1;
  cursor: pointer;
}

a.signup_forgot_password_link:hover {
  color: #888ea8;
}

.signup_divider {
  font-size: 13px;
  color: #888ea8;
  margin: 35px 0 38px 0;
}

.signup_social_btn {
  background: transparent;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  border-radius: 4px;
  padding: 12px 10px;
  width: 181px;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  color: @secondary-color;
  cursor: pointer;
  letter-spacing: 1px;

  .signup_social_icon {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
}

.signup_social_btn:hover,
.signup_social_btn:focus {
  border: 1px solid #e0e6ed;
  color: @secondary-color;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;