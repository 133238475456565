@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Nunito Sans", sans-serif;
}

.grey_fill_icon {
  height: 18px;
  width: 18px;
  color: #888ea8;
  fill: rgba(0, 23, 55, 0.08);
}

.secondary_btn {
  align-self: center;
  width: 100%;
  font-size: 14px;
  height: auto;
  border: 1px solid transparent;
  background-color: @secondary-color !important;
  border-color: @secondary-color;
  box-shadow: 0 10px 20px -10px @secondary-color;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.green_btn {
  align-self: center;
  width: 100%;
  // padding: 10px 14px;
  font-size: 14px;
  // letter-spacing: 2px;
  height: auto;
  border: 1px solid #027e54;
  background-color: #029b67 !important;
  box-shadow: 0 10px 20px -10px #027e54;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;

  &.outline {
    align-self: center;
    width: 100%;
    // padding: 10px 14px;
    font-size: 14px;
    // letter-spacing: 2px;
    height: auto;
    border: 1px solid #027e54;
    box-shadow: 0 10px 20px -10px #becbda;
    background-color: #ffffff !important;
    color: #027e54;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:focus,
    &:hover {
      box-shadow: none;
      background-color: #ffffff;
      border-color: #027e54;
      color: #027e54;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

.red_btn {
  &.outline {
    align-self: center;
    width: 100%;
    // padding: 10px 14px;
    font-size: 14px;
    // letter-spacing: 2px;
    height: auto;
    border: 1px solid #ff0000;
    box-shadow: 0 10px 20px -10px #becbda;
    background-color: #ffffff !important;
    color: #ff0000;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:focus,
    &:hover {
      box-shadow: none;
      background-color: #ffffff;
      border-color: #ff0000;
      color: #ff0000;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}

.action_btn {
  align-self: center;
  width: 100%;
  font-size: 14px;
  height: auto;
  border: 1px solid transparent;
  box-shadow: 0 10px 20px -10px #becbda;
  background-color: #ffffff;
  border-color: #67a8ee;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.ant-btn.action_btn:hover,
.ant-btn.action_btn:focus {
  box-shadow: none;
  background-color: #e6f4ff;
  border-color: #67a8ee;
  color: #1b2e4b;
}

.secondary_btn_disabled {
  align-self: center;
  width: 100%;
  padding: 10px 14px;
  font-size: 14px;
  // letter-spacing: 2px;
  height: auto;
  border: 1px solid transparent;
  background-color: #888ea8;
  border-color: #888ea8;
  // box-shadow: 0 10px 20px -10px #888ea8;
  color: #ffffff;
  border-radius: 4px;
  // cursor: pointer;
  // transition: 0.2s ease-out;
}

.secondary_btn_disabled:hover,
.secondary_btn_disabled:focus {
  box-shadow: none;
  border-color: #888ea8;
  background-color: #888ea8;
  color: #ffffff;
}

.secondary_btn:hover,
.secondary_btn:focus {
  box-shadow: none;
  border-color: @secondary-color;
  color: #ffffff;
}

.danger_btn {
  align-self: center;
  // width: 100%;
  // padding: 10px 14px;
  font-size: 14px;
  // letter-spacing: 2px;
  height: auto;
  border: 1px solid #ff0000;
  background-color: #ffffff !important;
  box-shadow: 0 10px 20px -10px #becbda;
  color: #ff0000;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  border: 1px solid #027e54;
  background-color: #029b67;
  box-shadow: 0 10px 20px -10px #027e54;
  color: #ffffff;

  &.outline {
    border: 1px solid #027e54;
    box-shadow: 0 10px 20px -10px #becbda;
    background-color: #ffffff;
    color: #027e54;
  }
  &:focus,
  &:hover {
    box-shadow: none;
    background-color: #027e54;
    border-color: #027e54;
    color: #ffffff;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.ant-btn {
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
  height: auto;
  font-size: 14px;
  align-self: center;

  &-default {
    border: 1px solid #bfc9d4;
    box-shadow: 0 10px 20px -10px #becbda;
    background-color: #ffffff;
    color: #1b2e4b;

    &:hover,
    &:focus {
      box-shadow: none;
      border: 1px solid #bfc9d4;

      background-color: #ffffff;
      color: #1b2e4b;
    }
  }

  &-primary {
    color: #fff;
    border-color: #bfc9d4;
    background: #001529;
    &:hover,
    &:focus {
      color: #fff;
      border-color: #001529;
      background: #001529;
    }
  }

  &-secondary {
    border: 1px solid transparent;
    width: auto;
    background-color: @secondary-color;
    border-color: @secondary-color;
    box-shadow: 0 10px 20px -10px @secondary-color;
    color: #ffffff;
    &:hover {
      background-color: @secondary-color;
      border-color: @secondary-color;
      box-shadow: none;
      color: #ffffff;
    }

    &:disabled {
      border: 1px solid transparent;
      background-color: #888ea8;
      border-color: #888ea8;
      color: #ffffff;
      box-shadow: none;

      &:hover,
      &:focus {
        box-shadow: none;
        border-color: #888ea8;
        background-color: #888ea8;
        color: #ffffff;
      }
    }
  }

  &-link {
    color: #006bb3;
    border-color: transparent;
    box-shadow: none;
    &:hover {
      color: #69b1ff;
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;
    }
  }

  &-action {
    border: 1px solid transparent;
    box-shadow: 0 10px 20px -10px #becbda;
    background-color: #ffffff;
    border-color: #67a8ee;
    color: #000;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: #e6eaff;
      border-color: #67a8ee;
      color: #1b2e4b;
    }
  }
  &-green {
    border: 1px solid #027e54;
    background-color: #029b67;
    box-shadow: 0 10px 20px -10px #027e54;
    color: #ffffff;

    &.outline {
      border: 1px solid #027e54;
      box-shadow: 0 10px 20px -10px #becbda;
      background-color: #ffffff;
      color: #027e54;
    }
    &:focus,
    &:hover {
      box-shadow: none;
      background-color: #027e54;
      border-color: #027e54;
      color: #ffffff;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}
.default_btn {
  align-self: center;
  // width: 100%;
  // padding: 10px 14px;
  font-size: 14px;
  // letter-spacing: 2px;
  height: auto;
  border: 1px solid #bfc9d4;
  box-shadow: 0 10px 20px -10px #becbda;
  background-color: #ffffff;
  color: #1b2e4b;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: #ffffff;
    border-color: #bfc9d4;
    color: #1b2e4b;
  }
}

.filter_btn {
  align-self: center;
  width: 100%;
  padding: 4px 16px;
  font-size: 14px;
  height: auto;
  border: 1px solid transparent;
  // box-shadow: 0 10px 20px -10px #becbda;
  background-color: #000000;
  border-color: #bfc9d4;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.reset_btn {
  align-self: center;
  width: 100%;
  padding: 4px 16px;
  font-size: 14px;
  height: auto;
  border: 1px solid transparent;
  // box-shadow: 0 10px 20px -10px #becbda;
  background-color: #ffffff;
  border-color: #737373;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.header_date_picker {
  width: 420px;
  height: 44px;
  padding: 6px 15px;
  color: #d3d3d3;
  border-radius: 6px;
  border: none;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  background-color: #fff;
}

.header_search_input .ant-input {
  background-color: transparent;
  width: 370px;
  height: 26px;
  color: #ffffff;
}

.header_search_input .ant-input-affix-wrapper {
  background-color: rgba(81, 83, 101, 0.28);
  background-image: none;
  border: 0 solid #d9d9d9;
  transition: all 0.3s;
  display: inline-flex;
  border-radius: 6px;
}

.header_search_input
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: none;
  border-right-width: 1px;
  z-index: 1;
}

.header_search_input .ant-input-affix-wrapper:hover {
  border-color: transparent;
  border-right-width: 0px;
}

.header_search_input .ant-input:hover {
  border-right-width: 0;
  border-color: transparent;
}

.header_search_input .ant-input:focus,
.header_search_input .ant-input-focused {
  border-color: transparent;
  box-shadow: transparent;
}

//container style
.sider_menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 6px;
  color: #0e1726;
  height: 33px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

//container's right border
.sider_menu .ant-menu-vertical .ant-menu-item::after,
.sider_menu .ant-menu-vertical-left .ant-menu-item::after,
.sider_menu .ant-menu-vertical-right .ant-menu-item::after,
.sider_menu .ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

//onclick
.sider_menu .ant-menu-item:active,
.sider_menu .ant-menu-submenu-title:active {
  background: #bfc9d4;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  border-radius: 6px;
}

//left icon
.sider_menu .ant-menu-light .ant-menu-item:hover,
.sider_menu .ant-menu-light .ant-menu-item-active,
.sider_menu
  .ant-menu-light
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open,
.sider_menu .ant-menu-light .ant-menu-submenu-active,
.sider_menu .ant-menu-light .ant-menu-submenu-title:hover {
  color: #282c34;
}

.ant-menu .ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border: solid rgb(212, 212, 212);
  border-width: 2px 0 0;
  margin-block: 1px;
  padding: 1;
  margin: 10px 16px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  // :global {
  height: 25px;
  line-height: 40px;
  // }
}

.layout_content_container.ant-layout {
  height: 100%;
  flex: 1;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dcdcdc;

  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 5px;
}

.header_search_input .ant-input {
  background-color: transparent;
  width: 370px;
  height: 26px;
  color: #ffffff;
}

.ant-divider-horizontal {
  margin-top: 5px !important;
}

.modal_container {
  .ant-modal-content {
    border-radius: 20px;
    padding: 10px;
  }
  .ant-modal-header {
    border-bottom: 0px;
    border-radius: 20px;
  }
  .ant-modal-footer {
    border-top: 0px;
    text-align: center;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    border: 1px solid #fbfdfc;
    background-color: #ffffff;
    box-shadow: 0 10px 20px -10px #ffffff;
    color: #ffffff;
  }
  .ant-btn-primary {
    color: #fff !important;
    background-color: @secondary-color !important;
    border-color: @secondary-color;
    box-shadow: 0 10px 20px -10px @secondary-color;
    color: #ffffff;
    border-radius: 4px;
  }
  .ant-btn {
    background-color: #fff;
    color: #001529;
  }

  .select_container {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }
}

.styles_modal_container__ZD3TM .ant-modal-footer {
  border-top: 0px;
  text-align: right;
}

.modal_container .ant-btn-primary {
  color: #fff !important;
  background-color: @secondary-color !important;
  border-color: @secondary-color;
  box-shadow: 0 10px 20px -10px @secondary-color;
  color: #ffffff;
  border-radius: 4px;
}
.modal_container .ant-btn {
  background-color: #fff;
  color: #001529;
}

.modal_container .select_container {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  border: 1px solid #bfc9d4;
  font-size: 15px;
}

.table_search_input .ant-input-affix-wrapper {
  width: 420px;
  height: 44px;
  padding: 6px 15px;
  color: #d3d3d3;
  border-radius: 6px;
  border: none;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
    rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  background-color: #fff;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

:where(.ant-select-single .ant-select-selector) {
  border-radius: 6px !important;
}

.table-search-select {
  .ant-select-selector {
    // width: 300px;
    min-height: 44px;
    border-radius: 6px !important;
    border: none !important;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
    background-color: #fff !important;
  }
}

.search-select {
  .ant-select-selector {
    // width: 300px;
    min-height: 44px;
    border-radius: 6px !important;
    border: none !important;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
    background-color: #fff !important;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  margin: auto;
  pointer-events: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  margin: auto;
}

.date-range-picker {
  // width: 300px;
  height: 44px;
  border-radius: 6px !important;
  border: none !important;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  background-color: #fff !important;
}

.filter-search {
  // width: 300px;
  height: 44px;
  border-radius: 6px !important;
  border: none !important;
  box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
  background-color: #fff !important;

  .ant-input {
    font-size: 14px;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #1677ff !important;

  background-color: #e6f4ff !important;
}

.ant-card {
  border-radius: 8px;
}

thead th.ant-table-cell {
  vertical-align: bottom;
}

.ant-table-tbody td.ant-table-cell {
  vertical-align: top;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  vertical-align: bottom !important;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  vertical-align: top !important;
}

.divider {
  border-width: 0px;
  height: 3px;
  background-color: rgb(223, 228, 232);
}

.label {
  font-weight: 600;
  margin-top: 9px;
}

.input {
  .ant-input {
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
    // min-width: 200px;
    width: 500px;
    // width: 60%;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
  }
}

.input-number {
  border: 1px solid #bfc9d4;
  font-size: 14px !important;
  width: 500px;
  height: 32px;
  align-items: center !important;
  padding: 0px 0px;
  border-radius: 6px;
}

.select_container {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }

  .ant-select.ant-select-in-form-item {
    width: 500px;
  }
}

.rules {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  padding-left: 2px;
}

.history_section_title {
  font-size: 14px;
  font-weight: 600 !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

.history_table {
  .ant-pagination-item-active,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #888ea8;
  }

  .ant-pagination-item-active a,
  .ant-pagination-item a:hover {
    color: #515365;
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .anticon {
    color: #000000d9;
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 12px;
    padding: 2px !important;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle !important;
    text-align: start !important;
    font-size: 12px !important;
    padding: 2px !important;
    background-color: #f5f8fa;
    border-bottom: 0;
  }
}

.date_container {
  .ant-picker {
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
    width: 500px;
  }

  .ant-picker-input > input {
    font-size: 15px;
  }
}

.select_container {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }

  .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }

  .ant-select.ant-select-in-form-item {
    width: 500px;
  }
}

// .settings-divider {
//   margin: 10px 0px !important;
//   border-color: silver !important;
// }

.asterisk {
  color: red !important;
  font-weight: 600;
  margin-right: 20;
}

.settings-divider {
  border-width: 0px;
  height: 2px;
  background-color: rgb(226, 226, 226);
  margin: 10px 0px !important;
}

.page-title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 40px;
}

.page-title-with-tabs {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0px;
}

.section_subtitle {
  font-weight: 600;
  font-size: 16px;
  padding-left: 40px;
  margin-bottom: 10px;
  text-decoration: underline;
}

.form-details {
  padding-left: 60px;

  label,
  .label-details {
    font-size: 14px;
    font-weight: 500px;
    // white-space: normal;
  }

  .ant-form-item {
    margin-bottom: 12px;
    &-label {
      overflow: unset;
    }
  }
  .ant-form-item-label > label.ant-form-item-no-colon::after {
    content: " ";
    margin-inline-end: 30px;
  }

  .ant-picker-range {
    height: 32px !important;
    align-items: center !important;
    border-radius: 6px !important;
    border: 1px solid #bfc9d4 !important;
    font-size: 14px !important;
    width: 500px !important;
    &.time-picker {
      width: 300px !important;
    }
  }
  .ant-input,
  .ant-picker,
  .ant-input-number {
    padding: 4px 10px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 14px;
    height: 32px;
    width: 500px;
  }
  .ant-select {
    max-width: 500px;
    .ant-select-selector {
      min-height: 32px !important;
      align-items: center !important;
      border-radius: 6px !important;
      border: 1px solid #bfc9d4 !important;
      font-size: 14px !important;
      width: 500px !important;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0px 10px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
    min-width: 200px;
    width: 500px;
    // height: 32px;
  }

  // fix money changers & merchants field issue
  .ant-input-affix-wrapper > input.ant-input {
    padding-right: 20px;
    border: none;
    outline: none;
    padding: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 1px;
    z-index: 1;
    // border-color: "transparent" !important;
    border-color: rgb(0 21 41 / 20%);
  }

  .two-rows-label .ant-form-item-label {
    // padding: 4px 0 !important;
    label {
      white-space: normal;
      margin-right: 12px;
      display: inline-block;
      text-align: right;
      line-height: 15px;
      width: 150px;
      &:after {
        content: none !important;
      }
      &.ant-form-item-required:before {
        position: absolute;
        left: -9px;
      }
    }
  }

  .two-rows-banner-label .ant-form-item-label {
    label {
      white-space: normal;
      margin-right: 12px;
      display: inline-block;
      text-align: right;
      line-height: 20px;
      width: 140px;
      &:after {
        content: none !important;
      }
      &.ant-form-item-required:before {
        position: absolute;
        top: 2px !important;
        right: 66px;
      }
    }
  }

  .two-rows-emergency-label .ant-form-item-label {
    label {
      white-space: normal;
      margin-right: 12px;
      display: inline-block;
      text-align: right;
      line-height: 20px;
      width: 140px;
      &:after {
        content: none !important;
      }
      &.ant-form-item-required:before {
        position: absolute;
        top: 2px !important;
        right: 91px;
      }
    }
  }
}

.modal-form {
  label,
  .label-details {
    font-weight: 600;
    font-size: 14px;
  }

  .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
    // padding-left: 50px;
  }
  .ant-picker-range {
    height: 32px !important;
    align-items: center !important;
    border-radius: 6px !important;
    border: 1px solid #bfc9d4 !important;
    font-size: 14px !important;
    width: 500px !important;
  }
  .ant-input,
  .ant-picker {
    padding: 4px 10px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 14px;
    height: 32px;
    width: "100%";
    text-align: left;
  }
  .ant-select {
    max-width: 500px;
    .ant-select-selector {
      height: 32px !important;
      justify-content: right;
      align-items: center !important;
      border-radius: 6px !important;
      border: 1px solid #bfc9d4 !important;
      font-size: 14px !important;
      width: auto;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    transition: none;
    margin: auto;
    pointer-events: none;
    text-align: left;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    margin: auto;
    text-align: left;
  }
}

.save-auth-form {
  border: 1px solid transparent;
  width: 100%;
  margin-bottom: 10px;
  background-color: @secondary-color;
  border-color: @secondary-color;
  box-shadow: 0 10px 15px -10px @secondary-color;
  color: #ffffff;
  &:hover {
    background-color: @secondary-color;
    border-color: @secondary-color;
    box-shadow: 0 5px 5px -5px @secondary-color;
    color: #ffffff;
  }

  &:disabled {
    border: 1px solid transparent;
    background-color: #888ea8;
    border-color: #888ea8;
    color: #ffffff;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: #888ea8;
      background-color: #888ea8;
      color: #ffffff;
    }
  }
}

.cancel-auth-form {
  border: 1px solid transparent;
  width: 100%;
  background-color: #ffffff;
  border-color: #e6e6e6;
  box-shadow: 0 10px 10px -10px #888ea8;
  color: @secondary-color;
  &:hover {
    background-color: #ffffff;
    border-color: #e6e6e6;
    box-shadow: 0 5px 5px -5px #888ea8;
    color: @secondary-color;
  }

  &:disabled {
    border: 1px solid transparent;
    background-color: #888ea8;
    border-color: #888ea8;
    color: #ffffff;
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: #888ea8;
      background-color: #888ea8;
      color: #ffffff;
    }
  }
}

.text-blue {
  color: #006bb3;
}

a.ant-typography,
.ant-typography a {
  color: #006bb3;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.md_editor_style textarea {
  &::selection {
    -webkit-text-fill-color: #ffffff !important;
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;