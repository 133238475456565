.tfa_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tfa_card {
  border-radius: 15px;
  border: 1px solid #e0e6ed;
  min-width: 50px;
  max-width: 490px;
  width: 100%;
}

.tfa_title {
  font-size: 32px;
  color: #3b3f5c;
  text-align: center;
}

.tfa_desc {
  font-size: 13px;
  color: #888ea8;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.authCodeStyle {
  width: 40px;
  height: 50px;
  margin: 7px;
  border-radius: 0.25rem;
  border: 1px solid #bfc9d4;
  text-align: center;
  font-size: 16px;
}

.authCodeContainerStyle {
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.unmatch_code_error_text {
  color: red;
  margin-top: -10px;
  margin-bottom: -30px;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;