.reset_password_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset_password_card {
  border-radius: 15px;
  border: 1px solid #e0e6ed;
  min-width: 50px;
  max-width: 490px;
  width: 100%;
  padding: 16px;
}

.reset_password_title {
  font-size: 18px;
  color: #3b3f5c;
  font-weight: 700;
  margin-bottom: 13px;
  text-align: center;
}
.reset_link_exp_title {
  font-size: 18px;
  color: #ff4d4f;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}
.reset_password_desc {
  font-size: 14px;
  color: #3b3f5c;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}

.reset_password_form_item {
  :global {
    .ant-form-item-label > label {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1.5;
      color: #3b3f5c;
    }

    .ant-input-affix-wrapper > input.ant-input {
      width: 100%;
      color: #3b3f5c;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 9px 14px;
    }

    .ant-input-affix-wrapper {
      border-radius: 6px;
      border: 1px solid #bfc9d4;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:hover {
      box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
      border-color: @secondary-color;
    }

    .ant-form-item-explain-error {
      font-size: 12px;
      line-height: 2;
      text-align: left;
    }
  }
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;