.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
  .select_container {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;