.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
}

.search_icon {
  color: #888ea8;
  font-size: 22px;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}
:global {
  .secondary_btn {
    padding: 10px 14px;
    font-size: 14px;
  }
}

.label {
  font-weight: 600;
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

.btn_container {
  margin: 8px;
}

.upload_photo {
  width: "100%";
  height: 150px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px dashed #888ea8;
  // padding-top: 50px;
  //   justify-content: "center";
  // align-content: center;
  // background-color: #888ea8;
  // align-items: center;
  // align-self: center;
  // text-align: center;
}
.input2 {
  :global {
    .ant-input {
      padding: 8px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 50%;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.input {
  :global {
    .ant-input {
      padding: 8px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 100%;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;