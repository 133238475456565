.dashboard_container {
  margin-top: 22px;
  padding: 0 20px 0 18px;
  min-height: calc(100vh - 170px);
}

.dashboard_card {
  position: relative !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  height: 100% !important;
  border: 1px solid #e0e6ed !important;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%) !important;
 
  
}
.chart_card {
  position: relative !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  height: 100% !important;
  border: 1px solid #e0e6ed !important;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%) !important;
  :global {
    .ant-card-body{
      padding: 0;
    }
  }
  
}

.dashboard_total_card_title {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #888ea8;
}

.dashboard_card_info {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #888ea8 !important;
  font-size: 16px;
}

.dashboard_total_card_subtitle {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #515365;
}

.dashboard_total_card_icon_wrapper {
  display: inline-block;
  padding: 10px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.dashboard_icon {
  width: 22px;
  height: 22px;
}

.chart_title,
.dashboard_section_title {
  letter-spacing: 0px;
  font-size: 18px;
  display: block;
  color: #0e1726;
  font-weight: 600;
  margin-bottom: 0;
}

.dashboard_chart_dropdown_menu {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e6ed;
  box-shadow: none;
  min-width: 160px;
  right: 0;
}

.chart_container {
  border: 1px solid #e0e6ed;
  width: "100%";
  height: 420px;
  margin: 20px;
  overflow: hidden;
  padding: 20px;
  background-color: #ffffff;
}

.bar_chart_container {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e0e6ed;
  padding: 20px;
}

.pie_chart_container {
  border: 1px solid #e0e6ed;
  width: "100%";
  height: 550px;
  margin: 20px;
  overflow: hidden;
  padding: 20px;
  background-color: #ffffff;
}

.menu_icon_style {
  color: #888ea8;
  font-size: 10px;
  margin-left: 40px;
}

.menu_text {
  color: #1b2e4b;
}

.menu_container {
  position: relative;
  padding: 9px 15px 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  transform: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  letter-spacing: normal;
  min-width: 100px;
  text-align: inherit;
  color: #1b2e4b;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  margin: 0 10px;
  overflow: hidden;
  background-color: #ffffff;
}

.menu_icon {
  width: 22px;
  height: 22px;
}


.iframe_currency{
  border: none;
  background: #fff;
  border-radius: 8px;
  margin-top: 15px;
}

.card_pair_container{
  
  margin-top: 20px
  // max-height: 600px;
  // overflow: auto;
}
.card_pair_transfer{
  padding: 15px;
  border-radius: 8px;
  background: #e6f4ff;
  // width: 100%;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  // align-items:center ;
  justify-content: space-between;
}
.card_pair_title_row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_pair_title{

  font-weight: bold;
  font-size: 16px;
  color: #b5b5c3;
  margin-top: 5px;
  
}
.card_pair_count{
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  background: #fff;
  font-size: 30px;
  font-weight: bold;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;