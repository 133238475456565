.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.history_section_title {
  font-size: 14px;
  font-weight: 600 !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

.section_detail {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

.section_filter_title {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  margin-top: 13px;
  justify-content: end;
}

.search_icon {
  color: #888ea8;
  font-size: 22px;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
      vertical-align: bottom !important;
    }
  }

  :global {
    .ant-table-tbody > tr > td {
      vertical-align: top !important;
      text-align: start !important;
    }
  }
}

.select_container_phone {
  :global {
    .ant-select-selector {
      height: 42px !important;
      .ant-select-selection-item {
        line-height: 36px !important;
      }
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 14px;
  }

  .ant-select.ant-select-in-form-item {
    width: 100%;
  }
}

.btn_container {
  margin: 8px;
}

.rules {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  padding-left: 2px;
}

.phone_container {
  display: flex;
  column-gap: 2px;
}
.phone_container > div:last-child {
  width: 100%;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;