.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.input_container {
  margin-left: 32px;
  :global {
    .ant-form-item {
      margin-bottom: 8px;
    }
  }
}

.input {
  :global {
    .ant-input {
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 14px;
      width: 100%;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.select_container {
  height: 36px;
  border-radius: 6px;
  border: 1px solid #bfc9d4;
  font-size: 14px;
  width: 100% !important;
}

.password_input {
  :global {
    .ant-input-affix-wrapper {
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 14px;
      width: 35%;
    }
  }

  :global {
    .ant-input-affix-wrapper > input.ant-input {
      padding-right: 20px;
    }
  }
}

.button {
  :global {
    .ant-btn {
      height: 50px;
      padding: 7px 20px;
      font-size: 16px;
      letter-spacing: 0px;
    }
  }
}

.reset_button {
  width: 10%;
  margin-top: 8px;
}

.switch {
  :global {
    .ant-switch-checked {
      background-color: #1890ff;
    }
  }
  margin-bottom: 10px;
}

.switch_container {
  justify-content: center;
  background-color: red;
}

// .label {
//   padding-top: 12px;

// }

.modal_title {
  font-size: 16px;
  font-weight: 600;
}

// .switch_label {

//   line-height: 30px;
//   margin-right: 30px;
// }

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
    // .ok_button {
    //   background-color: #805dca;
    //   color: #fff;
    //   margin: 10px;
    //   margin-top: 15px;
    // }

    // .cancel_button {
    //   background-color: #fff;
    //   color: #001529;
    //   margin: 10px;
    //   margin-top: 15px;
    // }

    // .modalPrimaryBtn {
    //   background-color: #805dca !important;
    //   border-color: #805dca !important;
    //   color: #fff !important;
    //   border-radius: 0.25rem;
    //   margin: 10px;
    // }

    // .modalSecondaryBtn {
    //   background-color: #fff;
    //   color: #001529;
    //   border-radius: 0.25rem;
    //   margin: 10px;
    // }
  }
}

.authCodeStyle {
  width: 40px;
  height: 50px;
  margin: 7px;
  border-radius: 0.25rem;
  border: 1px solid #bfc9d4;
  text-align: center;
  font-size: 16px;
}

.authWrongCodeStyle {
  width: 40px;
  height: 50px;
  margin: 7px;
  border-radius: 0.25rem;
  border: 1px solid red;
  text-align: center;
  font-size: 16px;
}

.authCodeContainerStyle {
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.leftContainer {
  :global {
    text-align: left;
    margin-bottom: 15px;
  }
}

.centerContainer {
  :global {
    text-align: center;
    margin-bottom: 15px;
  }
}

.bold {
  :global {
    font-size: 17px;
    font-weight: bold;
  }
}

.unmatchCodeErrorText {
  color: red;
  margin-top: -10px;
  margin-bottom: -30px;
}

.subTitleText {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.validation_message {
  font-size: 12px;
  color: #ff0000;
  margin-top: 4px;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;