.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
}

.search_icon {
  color: #888ea8 !important;
  opacity: 0.5;
  font-size: 15px !important;
  text-align: right;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
}

.select_container {
  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 42px;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
    }
  }
}

.btn_container {
  margin: 8px;
}

.input {
  :global {
    .ant-input {
      padding: 8px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 80%;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.label {
  font-weight: 600;
}

.input_number {
  :global {
    .ant-input-number-out-of-range input {
      color: black;
    }
    .ant-input-number-handler-up {
      border-top-right-radius: 6px;
    }
    .ant-input-number-handler-down {
      border-bottom-right-radius: 6px;
    }
  }
}

.section_filter_title {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  margin-top: 13px;
  justify-content: end;
}

.search_icon {
  color: inherit;
  font-size: 22px;
}

.form_details {
  :global {
    .ant-form-item {
      margin-bottom: 24px;
    }
  }
}
.section_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.section_subtitle {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 40px;
}

.section_detail {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}
.btn_container {
  margin: 8px;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;