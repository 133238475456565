.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.section_detail {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 24px;
}

.section_filter_title {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  margin-top: 13px;
  justify-content: end;
}

.search_icon {
  color: #888ea8 !important;
  opacity: 0.5;
  font-size: 15px !important;
  text-align: right;
}


.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }
  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}

.section_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;
}

.reminder_container {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  padding: 2px 10px;
  min-width: 200px;
  font-size: 13px;
}

.date_picker {
  padding: 8px 10px !important;
  border-radius: 6px !important;
  border: 1px solid #bfc9d4 !important;
  font-size: 15px !important;
  width: 80% !important;
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
      // width: 120%;
      // height: 200px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
      margin-left: 0px !important;
    }
    // .ant-btn {
    //   background-color: #fff;
    //   color: #001529;
    // }
    // .ant-form-item{
    //   margin-bottom: 0px;
    // }
    .ant-input[disabled] {
      color: #000;
      background: transparent;
      border: 0px;
      width: auto;
    }
  }
}

.btn_container {
  margin: 8px;
}
.modal_row_title {
  margin-bottom: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

.modal_row_details {
  margin-bottom: 20px;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

.label {
  font-weight: 600;
}

.multiple_select {
  :global {
    .ant-select-multiple .ant-select-selection-placeholder {
      right: unset;
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;