.chart_title_container{
  padding: 24px 24px 0 ;
  text-align: right;
}
.chart_title {
  font-weight: bold;
  font-size: 20px;
}
// .chart_sub_title {
//   font-weight: bold;
//   font-size: 16px;
//   color:#b5b5c3
// }
@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;