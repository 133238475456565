.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

.section_title {
  font-weight: 600;
  font-size: 18px;
}

.search_icon {
  color: #888ea8;
  font-size: 22px;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}
:global {
  .secondary_btn {
    padding: 10px 14px;
    font-size: 14px;
  }
}

.input_container {
  width: 50%;
}

.switch {
  :global {
    .ant-switch-checked {
      background-color: #1890ff;
    }
  }
}

.label {
  font-weight: 600;
}

.btn_container {
  margin: 8px;
}

.select_container {
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 42px;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 55%;
    }
  }
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;