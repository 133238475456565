.container {
  padding: 20px;
}

.card_container {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  border-radius: 6px;
  margin: 20px;
  padding: 40px;
}

// .section_title {
//   font-weight: 600;
//   font-size: 18px;
//   text-align: center;
// }

.search_icon {
  color: #888ea8;
  font-size: 22px;
}

.pagination_item {
  :global {
    .ant-pagination-item-active,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #888ea8;
    }
  }

  :global {
    .ant-pagination-item-active a,
    .ant-pagination-item a:hover {
      color: #515365;
    }
  }

  :global {
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-link-icon,
    .anticon {
      color: #000000d9;
    }
  }

  :global {
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
  }
}

.dashboard_card {
  margin-bottom: 40;
  position: relative !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  height: 100% !important;
  border: 1px solid #e0e6ed !important;
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%) !important;
}

.section_title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
}

.dashboard_container {
  margin-top: 22px;
  padding: 0 20px 0 18px;
  min-height: calc(100vh - 170px);
}

.section_filter_title {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  margin-top: 13px;
  justify-content: end;
}

.input {
  :global {
    .ant-input,
    .ant-input-number {
      padding: 8px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 35%;
    }

    .ant-input-number {
      padding: 4px 5px;
    }

    .ant-input-number-handler-wrap {
      border-radius: 0 6px 6px 0;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.input_full_width {
  :global {
    .ant-input,
    .ant-input-number {
      padding: 8px 10px;
      border-radius: 6px;
      border: 1px solid #bfc9d4;
      font-size: 15px;
      width: 100%;
    }

    .ant-input-number {
      padding: 4px 5px;
    }

    .ant-input-number-handler-wrap {
      border-radius: 0 6px 6px 0;
    }
  }
  :global {
    .ant-input:focus,
    .ant-input-focused {
      box-shadow: 0 0 0 1px rgb(0 21 41 / 20%);
    }
  }
}

.select_container {
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  border: 1px solid #bfc9d4;
  font-size: 15px;
  width: 35% !important;
}

.select_container {
  :global {
    .ant-select-single .ant-select-selector {
      max-height: 51px;
      overflow: auto;
      background-color: green;
    }
  }
}

.reminder_container {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  padding: 2px 10px;
  min-width: 200px;
  font-size: 13px;
}

.date_picker {
  padding: 8px 10px !important;
  border-radius: 6px !important;
  border: 1px solid #bfc9d4 !important;
  font-size: 15px !important;
  width: 80% !important;
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
      // width: 120%;
      // height: 200px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
      margin-left: 0px !important;
    }
    // .ant-btn {
    //   background-color: #fff;
    //   color: #001529;
    // }
    // .ant-form-item{
    //   margin-bottom: 0px;
    // }
    .ant-input[disabled] {
      color: #000;
      background: transparent;
      border: 0px;
      width: auto;
    }
  }
}

.modal_row_title {
  margin-bottom: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

.modal_row_details {
  margin-bottom: 20px;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;