.main_layout {
  height: 100vh;
}

.sider_menu_selected_item {
  :global {
    .ant-menu-sub.ant-menu-inline {
      background: transparent !important;
    }
  }
}

.header_search_input {
  border: 1px solid rgba(81, 83, 101, 0.28);
  background-color: rgba(81, 83, 101, 0.28);
  color: #888ea8;
  width: 370px;
  height: 36px;
  border-radius: 6px;
}

.search_icon {
  color: #888ea8;
  font-size: 22px;
  margin-right: 8px;
}

.flag_icon_style {
  height: 22px;
  width: 22px;
  margin-top: -4px;
  margin-right: 18px;
}

.icon_style {
  color: #ffffff;
  font-size: 22px;
  margin-right: 18px;
  padding-top: 22px;
}

.company_name {
  color: #ffffff;
  margin-right: 50px;
  margin-left: 10px;
}

.menu_icon_style {
  color: #888ea8;
  font-size: 10px;
  margin-left: 40px;
}

.menu_text {
  color: #1b2e4b;
}

.menu_container {
  position: relative;
  padding: 9px 15px 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  transform: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  letter-spacing: normal;
  min-width: 115px;
  text-align: inherit;
  color: #1b2e4b;
  box-shadow: none;
  max-height: 35px;
}

.header {
  box-shadow: 0px 20px 20px rgb(126 142 177 / 12%);
  background: #fafafa;
  border-radius: 0;
  justify-content: flex-start;
  min-height: 53px;
  width: 100%;
  border-bottom: 1px solid #ebedf2;
}

.dashboard {
  color: #888ea8;
  font-size: 17px;
  font-weight: 500;
}

.dashboard_sub_item {
  color: #515365;
  font-size: 16px;
  font-weight: 700;
  margin-left: 6px;
  font-size: 17px;
}

.dashboard:hover {
  color: #515365;
}
.content_container {
  flex: 1;
  color: rgb(241, 242, 243);
}

.toggle_sider {
  margin-right: 16px;
  border-width: 0;
}

.toggle_icon_style {
  color: #f1f2f3;
  font-size: 20px;
}

.sider {
  background: #f1f2f3;
  height: 100%;
  border-right: 1px solid rgb(215, 224, 234);
}

.sider_menu {
  background-color: #f1f2f3;
  padding: 20px;
}

.layout_content {
  flex: 1;
  height: 100%;
  color: black;
  background-color: #f1f2f3;
}
.footer {
  text-align: left;
  color: #d0d0d0;
}

.breadcrumb_margin_left {
  // margin-left: 20px;
  color: #fafafa;
}

.sider_menu_selected_item {
  :global {
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover,
    .ant-menu-item a:hover {
      color: #0e1726;
    }
  }
}

.notification_title {
  font-weight: 700;
  font-size: 18px;
}

.underline {
  text-decoration: underline;
}

.pop_over_container {
  // overflow: "auto";
  overflow-x: hidden;
  overflow-y: auto;
}

.list_item {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.notification_content {
  aspect-ratio: 1;
  overflow: auto;
}

.modal_container {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 10px;
    }
    .ant-modal-header {
      border-bottom: 0px;
      border-radius: 20px;
    }
    .ant-modal-footer {
      border-top: 0px;
      text-align: center;
    }
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      border: 1px solid #fbfdfc;
      background-color: #ffffff;
      box-shadow: 0 10px 20px -10px #ffffff;
      color: #ffffff;
    }
    .ant-btn-primary {
      color: #fff !important;
      background-color: @secondary-color !important;
      border-color: @secondary-color;
      box-shadow: 0 10px 20px -10px @secondary-color;
      color: #ffffff;
      border-radius: 4px;
    }
    .ant-btn {
      background-color: #fff;
      color: #001529;
    }
  }
  .select_container {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #bfc9d4;
    font-size: 15px;
  }
}

.popover_divider {
  border-color: rgb(216, 216, 216);
  border-width: 2px 0 0;
  margin: 10px 0px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: visible;
  text-overflow: ellipsis;
}

@primary-color: #001529;@secondary-color: #4361ee;@btn-border-radius-base: 0.25rem;@btn-default-bg: #805dca;@btn-default-color: #fff;